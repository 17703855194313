
/* Remove padding from Bootstrap container */
.container-fluid {
    padding: 0px !important;
    display: flex;
    flex-direction: column; 
    min-height: 100vh;
}

.main {
    flex-grow: 1;
    background-color:rgb(218, 215, 211);
}

.main h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 50px;
    color: #06400e;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}


/*styles for Home content*/
.row img {
    object-fit: cover; /* Set object-fit to cover */
    width: 100%;
}

.col-sm-12.col-md-8 {
    padding: 0;
}

.descrip {
    color: #cf964c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}
.descrip.des {
    margin-right: 2px;
}
.descrip.des1 {
    background-color: #2b0507;
}

.descrip.des2 {
    background-color: #070116;
}

.descrip.des3 {
    background-color: #3a190a;
}

.descrip h1 {
    margin-bottom: 20px;
    padding: 10px;
    
}

.p-des {
    font-size: 15px;
}

.line {
    display: block;
    border-bottom: 2px solid #cf964c;
    width: 250px;
    margin-top: 30px;
}

.h1-title {
    position: relative;
}
  
.descrip h1 .h1-title::before,
.descrip h1 .h1-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; /* Color of the lines for active item */
    left: 0;
    transform: scaleX(1);
    
}

.descrip h1 .h1-title::before {
    top: -2px;
}

.descrip h1 .h1-title::after {
    bottom: -2px;
}

.descrip p {
    padding: 10px;
}

 /*styles for Bakery content*/
.div-half {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 75%;
    margin: 0 auto;
}
.h2-center {
    margin-bottom: 20px;
}


/*styles for Carousel*/
.carousel-container .carousel .carousel-item.active::before,
.carousel-container .carousel .carousel-item.active::after {
  transform: scale(0);
}

.carousel img {
    object-fit: cover; /* Set object-fit to cover */
    width: 100%;
    height: 90vh;
    object-position: center top;
  }

.play-pause-button {
    border: none;
    background-color: transparent;
    font-size: 40px;;
    font-weight: bolder;
    position: absolute;
    top: -10px; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both vertically and horizontally */
}


/*styles for menu*/
.meal {
    margin-top: 30px;
    padding-bottom: 90px;
    color: #06400e;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
}

.meal h1 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 50px;
}

.meal .image-group {
    margin: 10px 30px 10px 30px;
}

.meal .image-group img {
    height: 200px;
}

.main .image-group h5{
    padding-top: 20px;
}

.meal .image-group p {
    margin-bottom: 30px;
}


/*medium - larger tablets and smaller desktops*/
@media (max-width: 992px) {
    .line {
        display: none;
    }
}  


/*small -tablets*/
@media (max-width: 768px) {
    
   
}

/*extra small - mobile devices*/
@media (max-width: 576px) {
 
    .main {
        margin-bottom: 100px;
    }

    .div-half {
        width: 100%;
    }

    .h2-center {
        font-size: 25px;
    }
}

