/*styles for Navigation*/
.custom-navbar-bg {
    background-color: #06400e;
}

.custom-navbar-text {
    color: #cf964c;
    font-family: 'Garamond', Times, serif;
    font-size: large;
    position: relative;
}

.custom-navbar-text.bold {
    font-weight: bolder;
    font-size: larger;
}

.custom-navbar-text.dropdown_title {
    background-color: #06400e;
}

.custom-navbar-text.dropdown_title.dropdown_item {
    color: #cf964c;
    margin-bottom: 5px;
}

.custom-navbar-text.dropdown_title.dropdown_item:hover {
    color: #cf964c;
    background-color: #062e0b;
}

.navbar .nav-item .dropdown-menu {
  background-color: #06400e;
  padding-top: 0;
  padding-bottom: 0;
}

.custom-navbar-text::before,
.custom-navbar-text::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px; /* Show a 1px line */
  background-color: #cf964c; /* Color of the lines */
  left: 0;
  transition: all 0.5s;
  transform: scaleX(0); /* Initially, hide the lines */
  transform-origin: center; /* Set the transform starts from center */
}

.custom-navbar-text::before {
  top: -2px; /* Position the line above the text */
}

.custom-navbar-text::after {
  bottom: -1px; /* Position the line below the text */
}

/* Style for lines on hover */
.custom-navbar-text:hover::before,
.custom-navbar-text:hover::after {
  transform: scaleX(1); /* Show the lines on hover */
}

.custom-navbar-text.dropdown_title.dropdown_item:hover::before,
.custom-navbar-text.dropdown_title.dropdown_item:hover::after {
  transform: scaleX(0); /* Show the lines on hover */
}

.custom-brand {
    color: #cf964c;
    font-size: xx-large;
    font-family: 'Lucida Handwriting';
    font-weight: 700;
}

.active {
    position: relative;
  }
  
.active::before,
.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #cf964c; /* Color of the lines for active item */
    left: 0;
    transform: scaleX(1);
    transform-origin: center;
}

.active::before {
    top: 9px;
}

.active::after {
    bottom: 10px;
}


@media (max-width: 768px) {
    .custom-brand {
        font-size: medium;
    }
}


@media (max-width: 480px) {
    .custom-brand {
        display: none;
    }
}